.container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px !important;
}

.accordion {
  width: 100%;
  height: 90px;
  overflow: hidden;
  box-shadow: 0px 4px 4px 0px rgba(195, 195, 195, 0.15);
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(244, 244, 244, 1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: linear 0.2s;

  &._dark {
    background-color: #15171c;
    border: 1px solid var(--bs-card-border-color);
    box-shadow: none;
  }
}

.header {
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: transparent;
  transition: linear 0.2s;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  .title {
    font-size: 18px;
  }
}

.buttonContainer {
  position: relative;
  width: 32px;
  height: 32px;

  .checkbox {
    position: absolute;
    z-index: -1;
    visibility: hidden;
  }

  .button {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgba(241, 241, 241, 1);
    cursor: pointer;

    &._dark {
      background-color: #1f212a;
    }

    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-84%) rotate(-45deg);
      width: 6px;
      height: 1px;
      background-color: rgba(122, 132, 159, 1);
      border-radius: 3px;
      transition: linear 0.2s;
    }

    &:after {
      content: '';
      position: absolute;
      right: 50%;
      top: 50%;
      transform: translateX(84%) rotate(45deg);
      width: 6px;
      height: 1px;
      background-color: rgba(122, 132, 159, 1);
      border-radius: 3px;
      transition: linear 0.2s;
    }
  }
}

.accordion-1,
.accordion-2,
.accordion-3,
.accordion-4,
.accordion-5,
.accordion-6,
.accordion-7,
.accordion-8,
.accordion-9,
.accordion-10 {
  &:has(.checkbox:checked) {
    height: 473px;
  }

  &:has(.checkbox:checked) .header {
    border-bottom-color: rgba(244, 244, 244, 1);

    &._dark {
      border-bottom-color: #1e2027;
    }
  }

  .buttonContainer > .checkbox:checked ~ .button {
    &::before {
      content: '';
      transform: translateX(-84%) rotate(45deg);
    }
    &::after {
      content: '';
      transform: translateX(84%) rotate(-45deg);
    }
  }
}

.body {
  padding: 24px;


  .cardsList {
    width: 100%;
    overflow-x: auto;
    display: flex;
    gap: 16px;
    padding-bottom: 20px;
    padding-top: 12px;
  }

  .card {
    position: relative;
    min-width: 240px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 14px;
    border: 1px solid rgba(244, 244, 244, 1);
    box-shadow: 0px 4px 4px 0px rgba(195, 195, 195, 0.15);
    padding: 16px;

    &._dark {
      background-color: #1b1c22;
      border: 1px solid var(--bs-card-border-color);
      box-shadow: none;

      .item {
        &._success {
          background-color: #1f212a;
        }

        &._warning {
          background-color: #242320;
        }

        &._danger {
          background-color: #302024;
        }
      }
    }

    .title {
      position: absolute;
      left: 50%;
      top: 0px;
      transform: translateX(-50%) translateY(-50%);
      background-color: #383838;
      width: 55px;
      height: 23px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #ffffff;
      border-radius: 30px;
    }
  }

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 8px;

    .kpi {
      font-size: 14px;
      font-weight: 400;
    }

    .sum {
      font-size: 13px;
      font-weight: 500;
    }

    &._success {
      background-color: rgba(226, 251, 195, 1);

      .sum {
        color: rgba(23, 198, 83, 1);
      }
    }

    &._warning {
      background-color: rgba(255, 248, 221, 1);

      .sum {
        color: rgba(246, 192, 0, 1);
      }
    }

    &._danger {
      background-color: rgba(255, 238, 243, 1);

      .sum {
        color: rgba(248, 71, 95, 1);
      }
    }
  }
}
