.container {
  width: 300px;
  // height: 60px;
  display: grid;
  grid-template-columns: 1fr 1fr 40px;
  grid-template-areas: 'input1 input2 button' 'range range range';
  column-gap: 10px;
  row-gap: 20px;

  & > :nth-child(1) {
    grid-area: input1;
  }
  & > :nth-child(2) {
    grid-area: input2;
  }
  & > :nth-child(3) {
    grid-area: button;
  }
  & > :nth-child(4) {
    grid-area: range;
    height: 5px;
  }
}

:global(.range-slider__thumb) {
  background-color: #1b84ff !important;
}
:global(.range-slider__range) {
  background-color: #1b84ff !important;
}
