.label {
  position: relative;
  width: 100%;
  height: fit-content;
}

.signature {
  position: absolute;
  left: 10px;
  top: -8px;

  span {
    position: absolute;
    left: 0px;
    top: 0px;
    display: inline-block;
    z-index: 0;
    font-size: 11px;
    letter-spacing: 1px;
    white-space: nowrap;
    // text-transform: uppercase;
    padding: 0px 5px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    z-index: 0;
  }
}

.baseInput {
  // width: 100%;
  // height: 40px;
  // line-height: 100%;
  // padding: 0px 10px;
  // background-color: transparent;
  // box-shadow: none;
  // border: 1px solid;
}

.customSelect {
  display: block;
  width: 100%;
  padding: 0.775rem 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  color: var(--bs-gray-700);
  background-color: var(--bs-body-bg) !important;
  background-clip: padding-box;
  border: 1px solid var(--bs-gray-300) !important;
  appearance: none;
  border-radius: 0.475rem;
  box-shadow: false;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    border-color: var(--bs-gray-300) !important;
  }
}

.countryMultiValue {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 0;
  background-color: hsl(0, 0%, 90%);
  border-radius: 2px;
  margin: 2px;
  box-sizing: border-box;

  .label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 2px;
    color: hsl(0, 0%, 20%);
    font-size: 85%;
    padding: 3px;
    padding-left: 6px;
    box-sizing: border-box;
  }

  .remove {
    position: relative;
    height: 26px;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    border-radius: 2px;
    padding-left: 4px;
    padding-right: 4px;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
      background-color: #ffbdad;
      color: #de350b;
    }
  }
}
