@import './svg.scss';

.statisticsContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 24px;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px #c3c3c326;
  border-radius: 8px;

  &._dark {
    background-color: #15171c;
    box-shadow: none;
    border: 1px solid #1e2027;
  }

  .header {
    margin-bottom: 34px;
  }

  .title {
    font-size: 18px;
    font-weight: 500;
  }

  .statistics {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    flex-wrap: wrap;

    &__isExternal {
      @media (max-width: 1690px) {
        justify-content: start;
        
      }
    }

    .section_1 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 24px;

      &__isExternal {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: 1588px) {
        width: 100%;
        .stat {
          min-width: 260px;
          width: 100%;
        }
      }
      @media (max-width: 1200px) {
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: 650px) {
        .stat {
          min-width: 230px;
        }
      }
      @media (max-width: 580px) {
        grid-template-columns: 1fr;
      }
    }

    .section_2 {
      position: relative;
      width: fit-content;
      height: fit-content;
      display: grid;
      max-width: 400px;
      width: 100%;
      grid-template-columns: 1fr 1fr;
      gap: 24px;

      @media (max-width: 1588px) {
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        .stat {
          width: 100%;
        }
      }

      @media (max-width: 1200px) {
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: 465px) {
        grid-template-columns: 1fr;
      }
    }
  }

  .moreInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 32px;

    &Button {
      position: relative;
      width: 100%;
      height: 20px;
      margin: 30px 0px 0px 0px;
      display: flex;
      justify-content: center;

      input {
        position: absolute;
        z-index: -1;
        visibility: hidden;
      }
    }

    .info {
      display: flex;
      justify-content: space-between;
      gap: 24px;
      flex-wrap: wrap;
    }

    .section_1 {
      display: flex;
      flex-direction: column;
      gap: 24px;

      @media (max-width: 1400px) {
        width: 100%;
      }

      .top {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 24px;

        @media (max-width: 1400px) {
          grid-template-columns: 1fr 1fr;
        }
        @media (max-width: 650px) {
          grid-template-columns: 1fr;
        }

        .column {
          position: relative;
          border: 1px solid rgba(244, 244, 244, 1);
          border-radius: 8px;
          box-shadow: 0px 4px 4px 0px rgba(195, 195, 195, 0.15);

          &Title {
            position: absolute;
            left: 50%;
            top: 0px;
            transform: translateX(-50%) translateY(-50%);
            background-color: #383838;
            width: 30px;
            height: 23px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            color: #ffffff;
            border-radius: 30px;
          }

          &._dark {
            background-color: #1b1c22;
            border: 1px solid #1e2027 !important;
            box-shadow: none;
          }

          .stat {
            box-shadow: none;
            border-radius: 0px;

            @media (max-width: 1400px) {
              width: 100%;
            }
          }
        }
      }

      .bottom {
        display: flex;
        gap: 24px;

        @media (max-width: 1400px) {
          width: 100%;

          .stat {
            width: 100%;
          }
        }
        @media (max-width: 560px) {
          flex-direction: column;
        }
      }
    }

    .section_2 {
      flex-grow: 1;
      max-height: 230px;
      max-width: 400px;
      display: flex;
      flex-direction: column;
      gap: 24px;

      @media (max-width: 1400px) {
        flex-direction: row;
      }
      @media (max-width: 440px) {
        flex-direction: column;
      }

      .stat {
        width: 100%;
        flex-grow: 1;
      }
    }
  }
}

.stat {
  width: 260px;
  height: 114px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(244, 244, 244, 1);
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(195, 195, 195, 0.15);
  padding: 23px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 1249px) {
    width: 100%;
  }

  &._dark {
    background-color: #1b1c22;
    border: 1px solid #1e2027 !important;
    box-shadow: none;
  }

  .title {
    font-size: 14px;
  }

  .paramContainer {
    display: flex;
    align-items: center;

    .param {
      font-size: 30px;
      line-height: 100%;
      // color: rgba(0, 0, 0, 1);
      white-space: nowrap;
      margin-left: 8px;
    }
  }
}

.stat_2 {
  width: 188px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  .title {
    font-size: 14px;
    line-height: 100%;
  }

  .num {
    font-size: 30px;
    font-weight: 700;
    line-height: 100%;
  }
}

.pushes {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 24px;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px #c3c3c326;
  border-radius: 8px;

  &._dark {
    background-color: #15171c;
    box-shadow: none;
    border: 1px solid #1e2027;
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;

    .titleContainer {
      display: flex;
      align-items: center;
    }

    .title {
      font-size: 18px;
      font-weight: 500;
    }
  }

  .table {
    width: 100%;
    margin-top: 20px;

    .buttonPush {
      position: relative;
      z-index: 1;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: rgba(241, 241, 241, 1);
      transform: rotate(90deg);
      cursor: pointer;

      &._dark {
        background-color: #1f212a;
      }

      &::before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-84%) rotate(-45deg);
        width: 6px;
        height: 1px;
        background-color: rgba(122, 132, 159, 1);
        border-radius: 3px;
        transition: linear 0.2s;
      }

      &:after {
        content: '';
        position: absolute;
        right: 50%;
        top: 50%;
        transform: translateX(84%) rotate(45deg);
        width: 6px;
        height: 1px;
        background-color: rgba(122, 132, 159, 1);
        border-radius: 3px;
        transition: linear 0.2s;
      }
    }

    .basket {
      transform: rotate(0deg);
      display: flex;
      justify-content: center;
      align-items: center;

      &::after,
      &::before {
        content: none !important;
      }
    }
  }
}

.filters {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;

  @media (max-width: 520px) {
    justify-content: flex-start;
  }

  .sort {
    width: 44px;
    height: 44px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f1f1f1;
    cursor: pointer;

    &::before {
      content: '';
      width: 18px;
      height: 18px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    &._base {
      &::before {
        background-image: $filterBase;
      }
    }

    &._dark {
      background-color: #1b1c22;

      &::before {
        background-image: $filterBaseDark;
      }
    }

    &._up {
      background-color: #1b84ff;
      &::before {
        background-image: $filterUp;
      }
    }

    &._down {
      background-color: #1b84ff;
      &::before {
        background-image: $filterDown;
      }
    }
  }

  .refresh,
  .filter {
    height: 44px;
    min-width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 0px 20px;
    background-color: #1b84ff;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;

    &::before {
      content: '';
      width: 15px;
      height: 15px;
      background-image: $iconFilter;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  .filter {
    color: #ffffff;
  }

  .refresh {
    min-width: 200px;
    background-color: #f9f9f9;

    &::before {
      width: 12px;
      height: 12px;
      background-image: $iconCloseFilters;
    }

    &._dark {
      background-color: #1b1c22;

      &::before {
        background-image: $iconCloseFiltersDark;
      }
    }
  }
}

.popupFilters {
  width: 100%;
  display: flex;
  flex-direction: column;

  .block {
    width: 100%;
    display: flex;
    flex-direction: column;

    .rangeContainer {
      display: flex;
      gap: 20px;
    }

    .inputsContainer {
    }

    .inputs {
      display: flex;
      gap: 10px;
      margin-bottom: 20px;
      margin-top: 10px;
    }

    .moreZero {
      width: fit-content;
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 20px;

      span {
        font-size: 15px;
        font-weight: 500;
      }

      input[type='checkbox'] {
        cursor: pointer;
      }
    }

    .title {
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 8px;
      margin-bottom: 20px;
    }

    .button {
      background-color: #f9f9f9;
      color: #4b5675;
      padding: 13px 14px 13px 14px;
      border-radius: 28px;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;

      &._dark {
        background-color: #1f212a;
      }

      &._active {
        background-color: #1b84ff;
        color: #ffffff;
      }
    }
  }
}

.popupPush {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 8px;

  @media (max-width: 670px) {
    flex-direction: column;
  }

  .section_1 {
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(244, 244, 244, 1);
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px rgba(195, 195, 195, 0.15);
    padding: 23px 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @media (max-width: 1249px) {
      width: 100%;
    }

    &._dark {
      background-color: #1b1c22;
      border: 1px solid #1e2027 !important;
      box-shadow: none;
    }
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 70px;

    .id {
      transform: translateY(6px);
      span {
        background-color: #383838;
        padding: 8px 7px;
        border-radius: 16px;
        color: #ffffff;
      }
    }

    .params {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      gap: 8px;

      &:last-child {
      }

      .badge {
        font-size: 12px;
        font-weight: 500;
        padding: 8px 7px;
      }
    }
  }

  .titles {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    padding-top: 16px;
    border-bottom: 1px solid #f4f4f4;

    &._dark {
      border-bottom: 1px solid #1e2027;
    }

    .title {
      font-size: 14px;
      font-weight: 400;

      &._translate {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
  .texts {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    padding-top: 16px;

    .text {
      font-size: 14px;
      font-weight: 400;

      &._translate {
        font-size: 15px;
        font-weight: 400;
      }
    }
  }

  .section_2 {
    display: flex;
    gap: 8px;

    @media (max-width: 600px) {
      flex-wrap: wrap;
    }

    .stat {
      min-height: 83px;
      padding: 13px;
      justify-content: center;

      .title {
        margin: 0px;
      }

      .param {
        font-size: 28px;
      }
      @media (max-width: 1249px) {
        width: 260px;
      }

      @media (max-width: 670px) {
        width: 100%;
        flex-grow: 1;
      }
    }
  }
}

.section_3 {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 20px 0px 0px 0px;

  .button {
    max-width: 260px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 0px 20px;
    background-color: #1b84ff;
    color: #ffffff !important;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;

    &:hover {
      color: #ffffff !important;
    }
  }
}

.pagePush {
  display: flex;
  gap: 24px;

  @media (max-width: 1270px) {
    flex-wrap: wrap;
  }

  .section_1 {
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(244, 244, 244, 1);
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px rgba(195, 195, 195, 0.15);
    padding: 23px 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @media (max-width: 1249px) {
      width: 100%;
    }

    &._dark {
      background-color: #1b1c22;
      border: 1px solid #1e2027 !important;
      box-shadow: none;
    }

    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 70px;

      .id {
        transform: translateY(6px);
        span {
          background-color: #383838;
          padding: 8px 7px;
          border-radius: 16px;
          color: #ffffff;
        }
      }

      .params {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        gap: 8px;

        &:last-child {
        }

        .badge {
          font-size: 12px;
          font-weight: 500;
          padding: 8px 7px;
        }
      }
    }

    .titles {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-bottom: 16px;
      padding-top: 16px;
      border-bottom: 1px solid #f4f4f4;

      &._dark {
        border-bottom: 1px solid #1e2027;
      }

      .title {
        font-size: 14px;
        font-weight: 400;

        &._translate {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
    .texts {
      width: 100%;
      display: flex;
      flex-direction: column;
      // padding-bottom: 16px;
      padding-top: 16px;

      .text {
        font-size: 14px;
        font-weight: 400;

        &._translate {
          font-size: 15px;
          font-weight: 400;
        }
      }
    }
  }

  .section_2 {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (max-width: 1270px) {
      width: 100%;
    }

    .head {
      display: flex;
      gap: 16px;

      .stat {
        max-width: 220px;

        @media (max-width: 1249px) {
          min-width: 220px;
        }

        @media (max-width: 1270px) {
          flex-grow: 1;
          width: 100%;
          max-width: none;
        }
      }

      @media (max-width: 1430px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: 1270px) {
        width: 100%;
        display: flex;
      }

      @media (max-width: 730px) {
        flex-wrap: wrap;
      }
    }

    .bottom {
      display: flex;
      gap: 16px;
      height: 100%;

      @media (max-width: 1430px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: 730px) {
        display: flex;
        flex-wrap: wrap;
      }

      .stat {
        width: 100%;
        width: 100%;
        height: 100%;

        @media (max-width: 730px) {
          height: auto;
        }

        .num {
          font-size: 20px;
        }
      }
    }
  }
}

.copyContainer {
  background-color: #f9f9f9;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f4;
  }
}
