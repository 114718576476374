@import './svg.scss';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  gap: 60px;
  padding: 0px 0px 30px 0px;

  @media (max-width: 655px) {
    gap: 30px;
  }
}

.cardContainer {
  height: 320px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
  border: 1px solid rgb(241, 241, 244);
  border-radius: 18px;
  padding: 0 50px;
}

.number {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  background-image: linear-gradient(to top, rgb(162, 37, 37), rgb(254, 71, 71));
  -webkit-background-clip: text;
  color: transparent;
}

.image {
  position: relative;
  width: 160px;
  height: 160px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}

.crown {
  position: absolute;
  left: -8px;
  top: -9px;
  width: 110%;
  aspect-ratio: 1/1;
  background-image: $sadSmile;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.name {
  display: inline-block;
  white-space: nowrap;
  padding: 5px 0px;
}

.id {
  display: inline-block;
  white-space: nowrap;
  padding: 10px 0px 0px 0px;
}

.userPage {
  position: absolute;
  right: 0px;
  bottom: 0px;

  width: fit-content;
  height: fit-content;

  & a > span {
    border: 2px solid #d11e35 !important;
  }
}
