.Toastify__toast-theme--colored.Toastify__toast--error {
  background-color: rgba(255, 0, 0, 0.7) !important;
}

// input:-webkit-autofill,
// input:-webkit-autofill:focus {
//   transition: background-color 0s 600000s, color 0s 600000s !important;
// }

input {
  color-scheme: var(--themModeCustom);
}

.rotateCharts {
  @media (max-width: 720px) {
    transform: rotate(90deg);
  }
}

.backdrop-blur {
  background-color: #000;
  opacity: 0.8 !important;
  backdrop-filter: blur(3rem);
}

.max-modal-width {
  max-width: 1050px;
  .modal-content {
    background-color: transparent !important;
  }
  @media (max-width: 720px) {
    width: 80%;
    position: absolute;
    left: 8%;
  }
}

.exelLoader {
  width: 20px;
  height: 20px;
  border: 3px solid #17c653;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.filtersSalesPanel {
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 20px;

  &_activator {
    display: none;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 42px;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
    border-radius: 0.475rem !important;
    cursor: pointer;

    input[type='checkbox'] {
      visibility: hidden;
      width: 0px;
      height: 0px;
      overflow: hidden;
    }

    & > .ki-up {
      display: none;
    }
  }

  &_item {
    display: block;
    flex: 1;
  }

  @media (max-width: 820px) {
    flex-wrap: wrap;
    &_item {
      min-width: 160px;
    }
  }

  @media (max-width: 500px) {
    &_activator {
      display: flex;
    }
    &_item {
      display: none;
    }
  }
}

.filtersSalesPanel:has(.filtersSalesPanel_activator > input:checked) .filtersSalesPanel_item {
  display: block;
}

.filtersSalesPanel_activator > input:checked ~ .ki-down {
  display: none;
}
.filtersSalesPanel_activator > input:checked ~ .ki-up {
  display: block;
}

.btn-active-bg-primary:hover,
.btn-active-bg-primary:focus,
.btn-active-bg-primary.active {
  background-color: var(--bs-primary) !important;
}


.parametersDialogs {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;

  @media(max-width: 1550px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media(max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media(max-width: 400px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
