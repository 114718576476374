.container {
  background: #0d0e12;
  border-radius: 15px;
}

.logo {
  position: absolute;
  top: 80px;
}

.greeting {
  color: white;
  font-size: 40px;
  margin-top: 200px;
  text-align: center;
  text-transform: uppercase;
  .img {
    width: 40px;
    margin: 20px;
  }
}

.hello {
  color: white;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 300;
  text-align: center;
}

.text {
  color: white;
  text-align: center;
  font-size: 20px;
  margin-top: 12px;
  font-weight: 300;
  padding: 0 100px 0 100px;
}

.wish {
  color: white;
  font-size: 20px;
  text-align: center;
  margin-top: 36px;
  font-weight: 300;
}

.closeButton {
  background: linear-gradient(90deg, #EA892B 0%, #E70E76 100%);
  border-radius: 50px;
  color: #000!important;
  margin-top: 36px;
  margin-bottom: 60px;
  font-weight: 300;
  width: 180px;
}

@media (max-width: 768px) {
  .logo {
    position: absolute;
    top: 40px !important;
    width: 250px;
  }

  .greeting {
    color: white;
    font-size: 24px;
    margin-top: 130px;
    .img {
      width: 30px;
      margin: 4px;
    }
  }

  .hello {
    font-size: 14px;
    margin-bottom: 0;
  }

  .text {
    font-size: 14px;
    padding: 2rem;
  }

  .wish {
    font-size: 14px;
    margin-top: 0;
  }
}
