@import './svg.scss';

$heightItem: 60px;

.light {
  background-color: rgb(255, 255, 255);
}

.dark {
  background-color: #15171c;
}

.gold {
  background-color: #ffb800;
}

.silver {
  background-color: #cbcbcb;
}

.bronze {
  background-color: #ffa857;
}

.bad {
  background-color: #ff5757;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .header {
    display: flex;
    margin-bottom: 25px;
  }

  .listContainer {
    height: 640px;
    overflow-y: auto;
  }

  .list {
    list-style: none;
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
  }

  .item {
    position: relative;
    width: 100%;
    min-height: $heightItem;
  }

  .blockScreen {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
    border: 1px solid rgb(241, 241, 244);
    border-radius: 18px;
    z-index: 1;
    overflow: hidden;

    @media (max-width: 390px) {
      gap: 5px;
    }

    .position {
      min-width: 65px;
      width: 65px;
      height: $heightItem;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-size: 18px !important;
        font-weight: 700 !important;

        @media (max-width: 590px) {
          font-size: 14px !important;
        }
      }

      @media (max-width: 590px) {
        min-width: 40px;
        width: 40px;
      }
      @media (max-width: 410px) {
        min-width: 35px;
        width: 35px;
      }
    }

    .user {
      width: 300px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 20px;
      overflow: hidden;

      @media (max-width: 590px) {
        padding-left: 10px;
        // overflow: visible;
      }

      @media (max-width: 450px) {
        padding-left: 0px;
      }

      .userLtc {
        display: none;

        @media (max-width: 570px) {
          display: inline;
        }
      }
    }

    .screenInfo {
      display: flex;

      .team {
        @media (max-width: 770px) {
          display: none;
        }
      }

      .stats {
        @media (max-width: 1200px) {
          display: none;
        }
      }

      @media (max-width: 590px) {
        display: none;
      }
    }

    .ltc {
      width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 570px) {
        display: none;
      }
    }

    .salary {
      display: flex;
      width: 100px;
      justify-content: center;
      align-items: center;
      padding-right: 20px;

      @media (max-width: 490px) {
        // max-width: 40px;
        padding: 0;
      }
    }

    .action {
      display: none;

      @media (max-width: 1200px) {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 70px;
      }

      @media (max-width: 450px) {
        min-width: 40px;
      }
    }
  }

  .info {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding-left: 20px;

    @media (max-width: 1200px) {
      justify-content: center;
      align-items: center;
      padding-left: 0px;
    }

    .team {
      width: 260px;
      height: $heightItem;
      display: flex;
      align-items: center;
      padding-left: 20px;

      @media (max-width: 1400px) {
        display: none;
      }
      // @media (max-width: 1200px) {
      //   display: flex;
      //   max-width: 150px;
      // }
    }

    .teamMobile {
      display: none;
      align-items: center;
      padding: 0 20px;

      @media (max-width: 1400px) {
        display: flex;
      }

      @media (max-width: 770px) {
        display: none;
      }
      // @media (max-width: 1200px) {
      //   display: none;
      // }
    }

    .stats {
      max-width: 550px;
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      align-items: center;
      gap: 10px;

      @media (max-width: 500px) {
        margin-bottom: 10px;
      }

      .appeals {
        display: flex;
        justify-content: center;
      }
    }
  }

  .separator {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      right: -5px;
      top: 50%;
      transform: translateY(-50%);
      width: 2px;
      height: 18px;
      background-color: #dbdfe9;
    }
  }

  .blockMobile {
    display: none;
    position: relative;
    width: 100%;
    padding: 40px 0px 20px 0px;
    transform: translateY(-20px);
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: rgb(241, 241, 244);
    border-radius: 0 0 18px 18px;
    z-index: 0;
    overflow: hidden;

    @media (max-width: 500px) {
      flex-direction: column;
      padding-bottom: 0px;
    }

    .teamWidthMobile {
      display: none;

      @media (max-width: 770px) {
        display: flex;
      }
      @media (max-width: 690px) {
        display: none;
      }
    }

    .teamMedium {
      display: none;

      @media (max-width: 690px) {
        display: flex;
      }
      @media (max-width: 500px) {
        display: none;
      }
    }

    &._active {
      display: flex;
    }
  }

  .teamSmall {
    display: none;

    @media (max-width: 500px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 20px;
      background-color: #f9f9f9;
    }

    .name,
    .link {
      display: flex;
      align-items: center;
    }
  }
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  gap: 60px;
  padding: 0px 0px 30px 0px;

  @media (max-width: 655px) {
    gap: 30px;
  }
}

.cardContainer {
  height: 320px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
  border: 1px solid rgb(241, 241, 244);
  border-radius: 18px;
  padding: 0 50px;

  &._1 {
    .number {
      background-image: linear-gradient(to top, rgb(180, 51, 1), rgb(255, 194, 1));
      -webkit-background-clip: text;
      color: transparent;
    }

    .crown {
      background-image: $star1;
    }

    .userPage {
      width: fit-content;
      height: fit-content;

      & a > span {
        border: 2px solid gold !important;
      }
    }
  }

  &._2 {
    .crown {
      background-image: $star2;
    }

    .number {
      background-image: linear-gradient(to top, rgb(123, 96, 110), rgb(225, 213, 209));
      -webkit-background-clip: text;
      color: transparent;
    }

    .userPage {
      width: fit-content;
      height: fit-content;

      & a > span {
        border: 2px solid silver !important;
      }
    }
  }

  &._3 {
    .crown {
      background-image: $star3;
    }

    .number {
      background-image: linear-gradient(to top, rgb(162, 37, 37), rgb(254, 111, 71));
      -webkit-background-clip: text;
      color: transparent;
    }

    .userPage {
      width: fit-content;
      height: fit-content;

      & a > span {
        border: 2px solid #cd7f32 !important;
      }
    }
  }
}

.image {
  position: relative;
  // max-width: 160px;
  // width: 100%;
  // aspect-ratio: 1/1;
  width: 160px;
  height: 160px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}

.crown {
  position: absolute;
  left: -27px;
  top: -27px;
  width: 133%;
  aspect-ratio: 1/1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.number {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.name {
  display: inline-block;
  white-space: nowrap;
  padding: 5px 0px;
}

.id {
  display: inline-block;
  white-space: nowrap;
  padding: 10px 0px 0px 0px;
}

.userPage {
  position: absolute;
  right: 0px;
  bottom: 0px;
}
