.card {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 21, 52, 0.5);
    border-radius: inherit;
    z-index: 0;
  }
}

.banner {
  position: relative;
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  z-index: 1;

  .titleContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      font-size: clamp(16px, 1.4vw, 24px);
      font-weight: 700 !important;
      white-space: nowrap;

      span {
        color: white;
        &:last-child {
          color: rgba(248, 40, 90, 1);
        }
      }
    }

    .image {
      background-image: url(../_metronic/assets/media/cup.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      max-width: 156px;
      width: 100%;
      height: 126px;
    }
  }

  .managers {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    // flex-wrap: wrap;
    gap: 40px;

    @media (max-width: 435px) {
      gap: 20px;
    }

    .manager {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 5px;
      min-height: clamp(130px, 1.4vw, 145px);
      height: 100%;
      max-width: 170px;
      aspect-ratio: 1/1;
      border-radius: 8px;
      background-color: rgba(79, 79, 79, 0.39);
      backdrop-filter: blur(6px);
      // overflow: hidden;
      padding: 0px 8px;

      @media (max-width: 435px) {
        min-height: clamp(125px, 1.4vw, 130px);
      }
    }

    .type {
      width: 30%;
      aspect-ratio: 2/1;
      background-image: linear-gradient(
        90deg,
        rgba(255, 153, 0, 1) 0%,
        rgba(255, 214, 108, 1) 100%
      );
      border-radius: 0px 0px 6px 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: rgba(17, 31, 58, 1);
      font-weight: 700 !important;
      font-size: clamp(13px, 1.4vw, 15px);
    }

    .image {
      width: 100%;
      max-width: 65px;
      aspect-ratio: 1/1;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 50%;

      @media (max-width: 435px) {
        max-width: 55px;
      }
    }

    .nameContainer {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .name {
      flex: 1;
      max-width: 150px;
      color: rgba(255, 255, 255, 1);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      line-height: 100%;
      font-size: 12px;
      font-weight: 400 !important;

      @media (max-width: 475px) {
        max-width: 110px;
      }
    }

    .pr {
      font-size: 15px;
      font-weight: bold;
      background: linear-gradient(90deg, rgba(255, 153, 0, 1) 0%, rgba(255, 214, 108, 1) 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 10px;

      @media (max-width: 435px) {
        margin-bottom: 5px;
      }
    }
  }
}
