:global(.react-select__container) {
  position: relative !important;
  min-height: 42px !important;
  height: fit-content !important;
}
:global(.react-select__control) {
  border: none !important;
  background-color: var(--bs-gray-100) !important;
  border-radius: 0.475rem !important;
}
:global(.react-select__menu) {
  background-color: var(--bs-gray-100) !important;
  border-radius: 0.475rem !important;
  z-index: 500 !important;
}

:global(.react-select__single-value) {
  color: var(--bs-text-gray-500) !important;
}

:global(.react-select__input-container) {
  grid-template-columns: auto 1fr !important;
}

:global(.react-select__multi-value) {
  background-color: var(--bs-modal-bg) !important;
  border: 1px solid var(--bs-text-gray-800) !important;
}

:global(.react-select__multi-value__label) {
  color: var(--bs-text-gray-800) !important;
}

:global(.react-select__input) {
  color: var(--bs-text-gray-800) !important;
  &::placeholder {
    color: var(--bs-text-gray-800) !important;
  }
}

:global(.hoverOptionSelector) {
  cursor: pointer;
}
:global(.hoverOptionSelector:hover) {
  background-color: #2684ff;
}
