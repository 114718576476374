$widthCalendar: 320px;
$mobDayWidth: 40px;

.container {
  &._dark,
  &._light {
    :global(.react-datepicker) {
      display: flex;
      background-color: transparent;
      border: none !important;

      @media (max-width: 865px) {
        flex-direction: column;
      }
    }

    :global(.react-datepicker__day) {
      color: #ffffff;
    }

    :global(.react-datepicker__day--selected) {
      background-color: rgba(4, 118, 252) !important;
      color: #ebebeb !important;
    }
    :global(.react-datepicker__day--in-range) {
      color: rgba(4, 118, 252) !important;
      background-color: rgba(222, 239, 252);
    }

    :global(.react-datepicker__day--range-start) {
      position: relative;
      color: #ebebeb !important;
      background-color: rgba(4, 118, 252) !important;
    }

    :global(.react-datepicker__day--range-end) {
      color: #ebebeb !important;
      background-color: rgba(4, 118, 252) !important;
    }

    :global(.react-datepicker__day:hover) {
      color: rgba(4, 118, 252);
      background-color: rgba(222, 239, 252);
      border-radius: 50%;
    }

    :global(.react-datepicker__day--in-selecting-range) {
      background-color: rgba(222, 239, 252);
      color: rgba(4, 118, 252);
    }

    :global(.react-datepicker__current-month) {
      font-size: 24px;
      color: white !important;
    }

    :global(.react-datepicker__year-dropdown-container--scroll) {
      font-size: 24px;
      color: white !important;
    }

    :global(.react-datepicker__month-dropdown) {
      font-size: 14px;
      color: white!important;
      background-color: var(--bs-gray-100)!important;
      border-color: transparent !important;
      font-weight: 600;
    }

    :global(.react-datepicker__year-dropdown) {
      font-size: 14px;
      color: white!important;
      background-color: var(--bs-gray-100)!important;
      border-color: transparent !important;
      font-weight: 600;
    }

    :global(.react-datepicker__month-dropdown-container--scroll) {
      font-size: 24px;
      color: white!important;
      font-weight: 600;
    }

    :global(.react-datepicker__month-read-view--down-arrow) {
      margin-top: 14px;
    }

    :global(.react-datepicker__year-read-view--down-arrow) {
      margin-top: 14px;
    }

    :global(.month-year-select
    .react-datepicker__month-container
    .react-datepicker__header
    .react-datepicker__current-month) {
      display: none;
    }

    :global(.react-datepicker__day-name) {
      color: white !important;
    }

    :global(.react-datepicker__navigation--previous) {
      background-color: rgba(4, 118, 252);
      border-radius: 5px;

      span {
        right: 0px;

        &:before {
          top: calc(50% - 3px);
          border-color: white;
        }
      }

      &:hover {
        background-color: rgba(4, 118, 252);

        span {
          &:before {
            border-color: white !important;
          }
        }
      }
    }
    :global(.react-datepicker__navigation--next) {
      background-color: rgba(4, 118, 252);
      border-radius: 5px;

      span {
        left: 0px;

        &:before {
          top: calc(50% - 3px);
          border-color: white;
        }
      }

      &:hover {
        background-color: rgba(4, 118, 252);

        span {
          &:before {
            border-color: white !important;
          }
        }
      }
    }

    :global(.react-datepicker__day--outside-month) {
      background-color: transparent !important;
      color: transparent !important;
    }
  }

  &._light {
    :global(.react-datepicker__day) {
      color: black;
    }

    :global(.react-datepicker__day:hover) {
      color: rgba(4, 118, 252);
      background-color: rgba(222, 239, 252);
    }

    :global(.react-datepicker__day--in-selecting-range) {
      background-color: rgba(222, 239, 252);
      color: rgba(4, 118, 252);
    }

    :global(.react-datepicker__current-month) {
      font-size: 24px;
      color: black !important;
    }

    :global(.react-datepicker__year-dropdown-container--scroll) {
      font-size: 24px;
      color: black!important;
      font-weight: 600;
    }

    :global(.react-datepicker__month-dropdown) {
      font-size: 14px;
      color: black!important;
      background-color: white!important;
      border-color: transparent !important;
      font-weight: 600;
    }

    :global(.react-datepicker__year-dropdown) {
      font-size: 14px;
      color: black!important;
      background-color: white!important;
      border-color: transparent !important;
      font-weight: 600;
    }

    :global(.react-datepicker__month-dropdown-container--scroll) {
      font-size: 24px;
      color: black!important;
      font-weight: 600;
    }

    :global(.react-datepicker__month-read-view--down-arrow) {
      margin-top: 14px;
    }

    :global(.react-datepicker__year-read-view--down-arrow) {
      margin-top: 14px;
    }

    :global(.month-year-select
    .react-datepicker__month-container
    .react-datepicker__header
    .react-datepicker__current-month) {
      display: none;
    }

    :global(.react-datepicker__day-name) {
      color: black !important;
    }
  }
}

.dark {
  background-color: var(--bs-gray-100);
  border-color: transparent !important;
}
.light {
  background-color: white;
  border-color: transparent !important;
}

:global(.react-datepicker__header) {
  background-color: transparent;
  border-bottom: 1px solid rgba(165, 165, 165, 0.3);
}

:global(.react-datepicker__current-month) {
  font-size: 24px;
  color: black;
}

:global(.react-datepicker__year-dropdown-container--scroll) {
  font-size: 24px;
  color: black;
  font-weight: 600;
}

:global(.react-datepicker__day--keyboard-selected) {
  background-color: transparent;
}

:global(.react-datepicker__month-dropdown) {
  font-size: 14px;
  color: black;
  background-color: white;
  border-color: transparent !important;
  font-weight: 600;
}

:global(.react-datepicker__year-dropdown) {
  font-size: 14px;
  color: black;
  background-color: white;
  border-color: transparent !important;
  font-weight: 600;
}

:global(.react-datepicker__month-dropdown-container--scroll) {
  font-size: 24px;
  color: black;
  font-weight: 600;
}

:global(.react-datepicker__month-read-view--down-arrow) {
  margin-top: 14px;
}

:global(.react-datepicker__year-read-view--down-arrow) {
  margin-top: 14px;
}

:global(.month-year-select
    .react-datepicker__month-container
    .react-datepicker__header
    .react-datepicker__current-month) {
  display: none;
}

:global(.react-datepicker__day--outside-month) {
  background-color: transparent !important;
  color: transparent !important;
}

:global(.react-datepicker__day-names) {
  display: grid;
  grid-template-columns: repeat(7, 50px);
  gap: 5px;
  padding: 0px 10px;
  margin-top: 10px;
}

@media (max-width: 865px) {
  :global(.react-datepicker__day-names) {
    display: flex;
    justify-content: center;
  }
}

:global(.react-datepicker__day-name) {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: black;
}

@media (max-width: 865px) {
  :global(.react-datepicker__day-name) {
    width: 40px;
  }
}

@media (max-width: 410px) {
  :global(.react-datepicker__day-name) {
    width: 35px;
  }
}
@media (max-width: 375px) {
  :global(.react-datepicker__day-name) {
    width: 30px;
  }
}

:global(.react-datepicker__month-container) {
  width: 100%;
  height: 100%;
}

:global(.react-datepicker__month) {
  width: 100%;
  margin: 0px;
  padding: 10px;
}

:global(.react-datepicker__week) {
  display: grid;
  grid-template-columns: repeat(7, 50px);
  grid-template-rows: 50px;
  gap: 5px;
  margin-bottom: 5px;
}

@media (max-width: 865px) {
  :global(.react-datepicker__week) {
    display: flex;
    justify-content: center;
    height: 40px;
  }
}
@media (max-width: 410px) {
  :global(.react-datepicker__week) {
    height: 35px;
  }
}
@media (max-width: 375px) {
  :global(.react-datepicker__week) {
    height: 30px;
  }
}

:global(.react-datepicker__day) {
  width: 100%;
  min-height: 100%;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

@media (max-width: 865px) {
  :global(.react-datepicker__day) {
    width: 40px;
  }
}
@media (max-width: 410px) {
  :global(.react-datepicker__day) {
    width: 35px;
  }
}
@media (max-width: 375px) {
  :global(.react-datepicker__day) {
    width: 30px;
  }
}

:global(.react-datepicker__day--in-range) {
  border-radius: 50%;
}

:global(.react-datepicker__day--range-end) {
  border-radius: 50% !important;
}

:global(.react-datepicker__day--selecting-range-end) {
  border-radius: 50% !important;
}

:global(.react-datepicker__day--range-start) {
  border-radius: 50% !important;
}

:global(.react-datepicker__day--selecting-range-start) {
  border-radius: 50% !important;
}

:global(.react-datepicker__day--selected) {
  border-radius: 50%;
}
:global(.react-datepicker__day--in-selecting-range) {
  border-radius: 50%;
}

:global(.react-datepicker__day--range-start:not(.react-datepicker__day--range-end)) {
  border-radius: 50% !important;
}

:global(.react-datepicker-wrapper) {
  flex-grow: 1;
  display: block;
  cursor: pointer;
}

:global(.react-datepicker-popper) {
  z-index: 2;
}

.otherContent {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
