.row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  & > div {
    width: 100%;
  }

  @media(max-width: 1590px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media(max-width: 1200px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
