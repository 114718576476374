.activityContainer {
  width: 100%;
  height: 15px;
  display: flex;
  // justify-content: space-between;
  color: white;

  .segmentContainer {
    position: relative;
    width: fit-content;
    height: fit-content;

    .gapTime {
      background-color: var(--tooltipMode);
      color: var(--tooltipTextMode);
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%) !important;
      width: fit-content;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 10px;
      white-space: nowrap;
      opacity: 0;
      pointer-events: none;
      transition: all 0.3s;
      border-radius: 8px;
      line-height: 100% !important;
      font-family: Inter, Helvetica, 'sans-serif' !important;
      z-index: 0;

      &::before {
        position: absolute;
        content: '';
        bottom: -6px;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;

        border-top: 7px solid var(--tooltipMode);
      }

      .marker {
        width: 5px;
        height: 50%;
        margin-right: 5px;
        border-radius: 3px;
      }
    }

    &:has(.segment:hover) {
      .gapTime {
        top: -41px;
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
      }
    }
    &:has(.gap:hover) {
      .gapTime {
        top: -41px;
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
      }
    }
    &:has(.segment) {
      .gapTime {
        .marker {
          background-color: rgba(23, 198, 84, 0.8);
        }
      }
    }
    &:has(.gap) {
      .gapTime {
        .marker {
          background-color: rgba(248, 40, 89, 0.8);
        }
      }
    }
  }

  .gap {
    background-color: rgba(248, 40, 89, 0.8);
  }

  .segment {
    background-color: rgba(23, 198, 84, 0.8);
  }

  .gap,
  .segment {
    position: relative;
    height: 15px;
    border-radius: 8px;
    transition: linear 0.2s;
    cursor: pointer;
    z-index: 1;

    &:hover {
      transform: scaleY(1.4);
    }
  }
}
