.container {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  flex-wrap: wrap;
}

.statsBlogger {
  display: grid;
  grid-template-columns: 1fr 1fr!important;
  gap: 24px;

  @media (max-width: 1573px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 1290px) {
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }
  @media (max-width: 1115px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 690px) {
    grid-template-columns: 1fr;
  }
}

.stats {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;

  @media (max-width: 1573px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 1290px) {
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
  }
  @media (max-width: 1115px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 690px) {
    grid-template-columns: 1fr;
  }

  .stat {
    width: 260px;
    height: 114px;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(244, 244, 244, 1);
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px rgba(195, 195, 195, 0.15);
    padding: 23px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: 1249px) {
      width: 100%;
    }

    &._dark {
      background-color: #1b1c22;
      border: #1b1c22;
      box-shadow: none;
    }

    .title {
      font-size: 14px;
    }

    .paramContainer {
      display: flex;
      align-items: center;

      .param {
        font-size: 30px;
        line-height: 100%;
        // color: rgba(0, 0, 0, 1);
        white-space: nowrap;
        margin-left: 8px;
      }
    }
  }
}

.circleContainer {
  display: flex;
  min-width: 279px;
  flex-grow: 1;
  height: 252px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(244, 244, 244, 1);
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(195, 195, 195, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 23px 16px;

  &._dark {
    background-color: #1b1c22;
    border: #1b1c22;
    box-shadow: none;
  }

  .title {
    width: 100%;
    text-align: start;
    font-size: 14px;
    font-weight: 600;
  }

  .statContainer {
    position: relative;
    width: 100%;
  }

  .stat {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(calc(-100% - 8px));
    font-size: 28px;
    line-height: 100%;
  }

  .text {
    color: rgba(119, 119, 119, 1);
    font-size: 14px;
    text-align: center;
  }

  .markers {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    font-size: 12px;

    span {
      display: flex;
      align-items: center;
      gap: 5px;
      white-space: nowrap;
    }

    .marker {
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 100%;
    }
  }
}
