.container {
  position: relative;
  width: fit-content;
  height: fit-content;

  &:has(.buttonOpen > input[type='checkbox']:checked) .dropdown {
    display: flex;
  }
  &:has(.addFilter > input[type='checkbox']:checked) .dropdown {
    display: flex;
  }
  &:has(.addGroup > input[type='checkbox']:checked) .dropdown {
    display: flex;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 10px;
    background-color: transparent;
    z-index: -1;
  }
}

.buttonOpen {
  position: relative;
  width: fit-content;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid var(--borderColor);
  border-radius: 8px;
  padding: 0px 14px;
  z-index: 1;
  cursor: pointer;

  &Title {
    position: relative;
    z-index: 1;
  }

  input[type='checkbox'] {
    position: absolute;
    width: 0;
    height: 0;
    visibility: hidden;
    z-index: -1;
  }

  svg {
    position: relative;
    z-index: 1;
  }

  &:has(input[type='checkbox']:checked) .arrow {
    transform: rotate(180deg);
  }

  .hoverEffect {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    z-index: 0;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      scale: 1;
      width: 0px;
      aspect-ratio: 1/1;
      background-color: var(--buttonHover);
      border-radius: 50%;
      animation-fill-mode: forwards;
    }
  }

  &:hover {
    .hoverEffect {
      &::before {
        animation: expandEffect 0.5s linear forwards;
      }
    }
  }

  @keyframes expandEffect {
    0% {
      width: 0px;
    }
    100% {
      width: 200%;
    }
  }
}

.searchWrapper {
  margin: 20px 10px 0px 10px;
}

.addFilter,
.addGroup {
  position: relative;
  width: fit-content;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0px 20px;
  cursor: pointer;

  input[type='checkbox'] {
    position: absolute;
    width: 0;
    height: 0;
    visibility: hidden;
    z-index: -1;
  }
  &Title {
    position: relative;
    line-height: 100%;
    z-index: 1;
  }

  &:before {
    content: '';
    position: relative;
    width: 12px;
    height: 10px;
    transform: translateY(-2px);
    background-image: var(--iconAddFilter);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: 1;
  }

  .hoverEffect {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    z-index: 0;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      scale: 1;
      width: 0px;
      aspect-ratio: 1/1;
      background-color: var(--buttonHover);
      border-radius: 50%;
      animation-fill-mode: forwards;
    }
  }

  &:hover {
    .hoverEffect {
      &::before {
        animation: expandEffect 0.5s linear forwards;
      }
    }
  }

  @keyframes expandEffect {
    0% {
      width: 0px;
    }
    100% {
      width: 200%;
    }
  }
}

.addGroup {
  &:before {
    background-image: var(--iconAddGroup);
    transform: translateY(-1px);
  }
}

.dropdown {
  display: none;
  position: absolute;
  top: calc(100% + 3px);
  left: 0;
  min-width: 200px;
  max-height: 400px;
  gap: 20px;
  border: 1px solid var(--borderColor);
  border-radius: 8px;
  background-color: var(--bg);
  z-index: 10000;

  .listWrapper {
    display: flex;
    flex-direction: column;

    .list {
      min-width: 200px;
      width: fit-content;
      list-style: none;
      margin: 0;
      padding: 0;
      flex: 1;
      margin-top: 20px;
      overflow-y: auto;
      overflow-x: visible;

      .item {
        padding: 7px 15px;
        cursor: pointer;
        white-space: nowrap;

        &:hover {
          background-color: var(--itemHover);
        }

        img {
          width: 25px;
          height: 20px;
          object-fit: contain;
          margin-right: 5px;
        }
      }

      .group {
        padding: 0;
        .label {
          display: block;
          width: 100%;
          height: 24px;
          display: flex;
          align-items: center;
          // color: #1b84ff;
          background-color: var(--itemHover);
          text-transform: uppercase;
          font-size: 12px;
          padding-left: 10px;
          margin: 3px 0px;
        }

        &:hover {
          background-color: transparent;
        }

        .groupOptions {
          list-style: none;
          padding: 0;
        }
      }
    }
  }

  .selectedListWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px 10px;

    .action {
      width: 100%;
    }

    .selectedList {
      min-width: 220px;

      display: flex;
      gap: 5px;
      flex-wrap: wrap;
      list-style: none;
      margin: 0;
      padding: 0;
      margin-top: 20px;
      overflow: auto;

      .item {
        display: flex;
        align-items: center;
        gap: 5px;
        background-color: #1b84ff;
        color: white;
        padding: 4px 9px;
        border-radius: 20px;
        white-space: nowrap;
        font-size: 13px;

        img {
          width: 25px;
          height: 20px;
          object-fit: contain;
        }

        .close {
          width: 10px;
          height: 10px;
          background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDEwIDEwIj48cGF0aCBmaWxsPSIjZmZmIiBmaWxsLW9wYWNpdHk9Ii40OCIgZD0iTTkuMjIuMjJhLjc1Ljc1IDAgMCAwLTEuMDYgMEw0LjcyIDMuNjYgMS4yOC4yMkEuNzUuNzUgMCAwIDAgLjIyIDEuMjhsMy40NCAzLjQ0TC4yMiA4LjE2YS43NS43NSAwIDAgMCAxLjA2IDEuMDZsMy40NC0zLjQ0IDMuNDQgMy40NGEuNzUuNzUgMCAwIDAgMS4wNi0xLjA2TDUuNzggNC43MmwzLjQ0LTMuNDRhLjc1Ljc1IDAgMCAwIDAtMS4wNloiLz48L3N2Zz4=');
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          cursor: pointer;

          &:hover {
            background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDEwIDEwIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNOS4yMi4yMmEuNzUuNzUgMCAwIDAtMS4wNiAwTDQuNzIgMy42NiAxLjI4LjIyQS43NS43NSAwIDAgMCAuMjIgMS4yOGwzLjQ0IDMuNDRMLjIyIDguMTZhLjc1Ljc1IDAgMCAwIDEuMDYgMS4wNmwzLjQ0LTMuNDQgMy40NCAzLjQ0YS43NS43NSAwIDAgMCAxLjA2LTEuMDZMNS43OCA0LjcybDMuNDQtMy40NGEuNzUuNzUgMCAwIDAgMC0xLjA2WiIvPjwvc3ZnPg==');
          }
        }
      }
    }
  }
}
