.container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &_fullScreen {
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100vh;
    z-index: 105;
    padding: 20px;
    backdrop-filter: blur(50px);
    background-color: var(--bgFullScreen);
  }
}

.button {
  height: 34px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 12px;
  border-radius: 8px;
  border: none;
  background-color: var(--bgButtons);
  color: var(--text);

  svg {
    path {
      fill: var(--svgFill);
    }
  }

  .clearColumns {
    position: relative;
    width: 20px;
    height: 20px;
    cursor: pointer;

    .plug {
      position: absolute;
      left: 0px;
      top: -7px;
      width: 150%;
      height: 34px;
    }

    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%) rotateZ(-45deg);
      width: 80%;
      height: 1px;
      background-color: var(--text);
      transition: linear 0.3s;
    }

    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%) rotateZ(45deg);
      width: 80%;
      height: 1px;
      background-color: var(--text);
      transition: linear 0.3s;
    }
  }
}

.filtersContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.filters {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;
}

.filtersListContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .groups {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
  }

  .list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;

    .element {
      display: flex;
      align-items: center;
      gap: 5px;
      background-color: #1b84ff;
      color: white;
      padding: 4px 9px;
      border-radius: 20px;
      white-space: nowrap;
      font-size: 13px;

      &._filter {
        background-color: black;
      }

      &Remove {
        width: 10px;
        height: 10px;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDEwIDEwIj48cGF0aCBmaWxsPSIjZmZmIiBmaWxsLW9wYWNpdHk9Ii40OCIgZD0iTTkuMjIuMjJhLjc1Ljc1IDAgMCAwLTEuMDYgMEw0LjcyIDMuNjYgMS4yOC4yMkEuNzUuNzUgMCAwIDAgLjIyIDEuMjhsMy40NCAzLjQ0TC4yMiA4LjE2YS43NS43NSAwIDAgMCAxLjA2IDEuMDZsMy40NC0zLjQ0IDMuNDQgMy40NGEuNzUuNzUgMCAwIDAgMS4wNi0xLjA2TDUuNzggNC43MmwzLjQ0LTMuNDRhLjc1Ljc1IDAgMCAwIDAtMS4wNloiLz48L3N2Zz4=');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        cursor: pointer;

        &:hover {
          background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDEwIDEwIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNOS4yMi4yMmEuNzUuNzUgMCAwIDAtMS4wNiAwTDQuNzIgMy42NiAxLjI4LjIyQS43NS43NSAwIDAgMCAuMjIgMS4yOGwzLjQ0IDMuNDRMLjIyIDguMTZhLjc1Ljc1IDAgMCAwIDEuMDYgMS4wNmwzLjQ0LTMuNDQgMy40NCAzLjQ0YS43NS43NSAwIDAgMCAxLjA2LTEuMDZMNS43OCA0LjcybDMuNDQtMy40NGEuNzUuNzUgMCAwIDAgMC0xLjA2WiIvPjwvc3ZnPg==');
        }
      }
    }

    .lastElements {
      background-color: black;
    }

    // .element {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   gap: 8px;
    //   padding: 5px 10px;
    //   border-radius: 8px;
    //   background-color: var(--bgColor);

    //   &Remove {
    //     position: relative;
    //     width: 15px;
    //     height: 15px;
    //     cursor: pointer;

    //     &::before,
    //     &::after {
    //       content: '';
    //       position: absolute;
    //       top: 50%;
    //       left: 50%;
    //       width: 100%;
    //       height: 1px;
    //       background-color: var(--closeColor);
    //       transform-origin: center;
    //     }

    //     &::before {
    //       transform: translate(-50%, -50%) rotate(45deg); /* Перша діагональна лінія */
    //     }

    //     &::after {
    //       transform: translate(-50%, -50%) rotate(-45deg); /* Друга діагональна лінія */
    //     }

    //     &:hover {
    //       &::before,
    //       &::after {
    //         height: 2px;
    //       }
    //     }
    //   }
    // }

    .otherElements {
      position: relative;
      cursor: pointer;

      &:hover {
        .elements {
          display: flex;
        }
      }

      .elements {
        position: absolute;
        bottom: 0px;
        left: 50%;
        min-width: 65px;
        max-width: 150px;
        display: none;
        flex-direction: column;
        align-items: flex-start;
        transform: translateY(calc(100% + 10px)) translateX(-50%);
        background-color: black;
        border-radius: 8px;
        z-index: 100;
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: -10px;
          left: 50%;
          transform: translateX(-50%);
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 10px solid var(--bgColor);
        }

        .element {
          display: block;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  .name {
    font-size: 14px;
  }
}

.actions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}

.selectContainer {
  position: relative;
  width: fit-content;
  height: fit-content;

  &:hover {
    .selectClose {
      display: flex;
    }
  }

  .selectClose {
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(45%) translateY(-45%);
    width: 18px;
    height: 18px;
    display: none;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid var(--closeColor);
    background-color: var(--bgColor);
    z-index: 2;
    cursor: pointer;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 70%;
      height: 1px;
      background-color: var(--closeColor);
      transform-origin: center;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg); /* Перша діагональна лінія */
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg); /* Друга діагональна лінія */
    }

    &:hover {
      &::before,
      &::after {
        height: 2px;
      }
    }
  }

  .checkedItem {
    width: fit-content;
    height: fit-content;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 30px;
    color: white;
    background-color: rgb(38, 132, 255);
    border-radius: 3px;
    font-size: 14px;
    font-weight: 600;
    line-height: 100%;
    overflow: hidden;

    .name {
      width: 100%;
      padding: 8px 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .delete {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;

      cursor: pointer;

      &:hover {
        background-color: rgb(255, 61, 61);
      }

      span {
        &:before {
          color: white;
          opacity: 1;
        }
      }
    }
  }

  .apply {
    position: sticky;
    bottom: -4px;
    height: 42px;
    width: 100%;
    border-radius: 3px;
    color: white;
    border: none;
  }
}
