$width: 7px;
$height: 7px;

.container {
  width: 100%;
  height: 100%;
}

.header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0px;

  h2 {
    margin: 0;
    line-height: 100%;
  }
}

.dotsContainer {
  height: fit-content !important;
  margin: 0 !important;
  padding: 0 !important;
  display: flex !important;

  :global(.slick-active ~ li) {
    transform: translateX(calc($width + 4px));
  }

  :global(.slick-active div) {
    width: calc($width + 10px);
    border-radius: 5px;
    background-color: #008ffb;
  }

  li {
    height: $height !important;
    width: $width !important;
    margin: 0px 3px !important;
    box-sizing: border-box;
    transition: all 0.3s ease;
  }
}

:global(.slick-dots) {
  width: fit-content;
  height: fit-content;
  top: 0px;
  bottom: 0 !important;
  right: 0px !important;
}

.customDots {
  width: $width;
  height: $height;
  background-color: #b5b5c3;
  border-radius: 50%;
  z-index: 10;
  transition: all 0.3s ease;

  &::before {
    content: none;
  }
}
