.crypto {
  background-color: #f061ae;
  color: white;
  padding: 2px 4px 2px 4px;
  font-weight: 700;
}

.bank {
  background-color: #de995a;
  color: white;
  padding: 2px 4px 2px 4px;
  font-weight: 700;
}

.add {
  border: 1px #1b84ff4d dashed;
}

.overflowText {
  // width: inherit;
  display: block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.copyContainer {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
