.container {
  width: 100%;
  height: fit-content;
  overflow-x: auto;
}

.tableContainer {
  width: 100%;
  flex: 1;
  // min-width: 100%;
  display: flex;
  flex-direction: column;
}

.tabs {
  min-width: 100%;
  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  @media (max-width: 720px) {
    flex-direction: column;
    margin-bottom: 24px;
  }

  .tab {
    height: 40px;
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    background-color: var(--tab);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
    border-radius: 20px 20px 0px 0px;
    padding: 0px 20px 0px 20px;
    cursor: pointer;

    @media (max-width: 720px) {
      width: 100%;
      min-height: 50px;
      justify-content: center;
      border-radius: 3px;
    }

    &._active {
      background-color: var(--tabActive);

      .name {
        span {
          color: var(--textActive);
        }
      }
    }

    .name {
      display: flex;
      align-items: center;
      gap: 8px;

      .svg {
        path {
          fill: var(--svgFill);
        }

        &._active {
          path {
            fill: var(--svgFillActive);
          }
        }
      }

      span {
        font-size: 13px;
        font-weight: 600;
        color: var(--text);
        white-space: nowrap;
      }
    }

    .elements {
      min-width: 100px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 8px;
      background-color: rgba(27, 132, 255, 1);
      border-radius: 30px;
      cursor: default;
      z-index: 1;

      span {
        color: rgba(255, 255, 255, 1);
        font-size: 11px;
      }

      svg {
        cursor: pointer;
      }
    }
  }
}

.actions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--bg);
  padding: 20px;

  @media (max-width: 630px) {
    flex-direction: column-reverse;
    align-items: start;
    gap: 20px;
  }

  div {
    margin: 0 !important;
  }

  .buttons {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .button {
    height: 34px;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 8px 12px;
    border-radius: 8px;
    border: none;
    background-color: var(--bgButtons);
    color: var(--text);

    svg {
      path,
      polygon {
        fill: var(--svgFill);
      }
    }

    .clearColumns {
      position: relative;
      width: 20px;
      height: 20px;
      cursor: pointer;

      .plug {
        position: absolute;
        left: 0px;
        top: -7px;
        width: 150%;
        height: 34px;
      }

      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%) rotateZ(-45deg);
        width: 80%;
        height: 1px;
        background-color: var(--text);
        transition: linear 0.3s;
      }

      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%) rotateZ(45deg);
        width: 80%;
        height: 1px;
        background-color: var(--text);
        transition: linear 0.3s;
      }
    }
  }
}

.table {
  height: fit-content;
  max-height: 744px;
  overflow-x: auto;
  overflow-y: auto;
  border: 1px solid var(--borderTable);

  .field {
    position: relative;
    width: 100%;
    background-color: var(--bg);
  }

  .row {
    position: relative;

    &:nth-child(even) {
      .cell {
        background-color: var(--bgRowTable);
      }
    }

    &:hover {
      .cell {
        background-color: var(--bgRowTableHover);
      }
    }

    &._active {
      .cell {
        background-color: var(--bgRowTableActive);
      }
    }
  }

  .cell {
    padding: 20px 10px;
    white-space: nowrap;
    outline: 1px solid var(--borderTable);

    &._body {
      color: var(--text2);
      font-weight: 500;
    }

    &._active {
      color: var(--textActive);
      font-weight: 700;
      cursor: pointer;
    }

    &._sticky {
      position: sticky;
      top: 0;
      left: 62px;
      z-index: 0;
      background-color: var(--bg);

      @media (max-width: 500px) {
        position: relative;
        left: 0;
      }
    }
  }

  .titles,
  .bottom {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: var(--bg);

    @media (max-width: 500px) {
      position: relative;
    }
  }

  .titles {
    &:hover {
      .cell {
        background-color: var(--bg);
      }
    }
  }

  .bottom {
    bottom: 0;

    &:hover {
      .cell {
        background-color: var(--bg);
      }
    }
  }

  .title {
    vertical-align: middle;

    span {
      cursor: pointer;
      color: var(--text);
    }

    .svg {
      opacity: 0;
      margin-left: 5px;
      cursor: pointer;

      &._down {
        opacity: 1;
        transform: rotateZ(180deg);
      }
      &._up {
        opacity: 1;
        transform: rotateZ(0deg);
      }

      path {
        stroke: var(--svgFill);
      }
    }
  }

  .checkboxContainer {
    position: sticky;
    top: 0;
    left: 0px;
    z-index: 0;
    min-width: 62px;
    min-height: 62px;
    background-color: var(--bg);

    @media (max-width: 500px) {
      position: relative;
    }

    input {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 22px;
      height: 22px;
      cursor: pointer;
    }
  }
}

.kanbanContainer {
  width: 100%;
  display: flex;

  @media (max-width: 500px) {
    flex-direction: column;
  }

  .menu {
    width: 260px;
    display: flex;
    flex-direction: column;

    @media (max-width: 500px) {
      width: 100%;
    }

    .tab {
      height: 45px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      background-color: var(--bg);
      padding: 0px 25px;
      cursor: pointer;

      &._active {
        background-color: var(--bgRowTableActive);

        .name {
          span {
            color: var(--textActive);
          }
        }
      }
    }

    .name {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;

      .svg {
        path {
          fill: var(--svgFill);
        }

        &._active {
          path {
            fill: var(--svgFillActive);
          }
        }
      }

      span {
        font-size: 14px;
        font-weight: 600;
        color: var(--text);
        white-space: nowrap;
      }
    }
  }

  .kanban {
    width: 100%;
    max-height: 400px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 20px;

    .title {
      background-color: var(--bg);
      color: var(--text) !important;
      font-size: 16px !important;
      font-weight: 600 !important;
      min-height: 40px;
    }

    :global(.react-kanban-board) {
      flex: 1 !important;
      width: inherit !important;
      align-items: start !important;
      justify-content: center;
      overflow-y: auto !important;

      & > div {
        position: relative;
        width: inherit;
        display: block;
        background-color: var(--bg);
      }
    }

    :global(.react-kanban-column-header) {
      display: none;
    }

    :global(.react-kanban-column) {
      width: 100%;
    }

    :global([draggable='false']) {
      & > div {
        width: 98%;
      }
    }
    :global([draggable='true']) {
      & > div {
        width: 98%;
      }
    }

    .card {
      background-color: var(--bgRowTableHover);
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 10px;
      margin-bottom: 8px;
      border-radius: 8px;

      svg {
        margin-right: 8px;
      }

      .add {
        position: relative;
        width: 20px;
        height: 20px;
        cursor: pointer;

        &::before {
          content: '';
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 0px;
          transform: translateX(0%) translateY(-50%);
          width: 80%;
          height: 1px;
          background-color: var(--text);
          transition: linear 0.3s;
        }
        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 0px;
          transform: translateX(0%) translateY(-50%) rotateZ(90deg);
          width: 80%;
          height: 1px;
          background-color: var(--text);
          transition: linear 0.3s;
        }

        &._remove {
          &::before {
            transform: translateX(0%) translateY(-50%) rotateZ(45deg);
          }
          &::after {
            transform: translateX(0%) translateY(-50%) rotateZ(-45deg);
          }
        }
      }
    }
  }
}

.button_withHowever {
  position: relative;

  .hoverEffect {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    z-index: 0;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      scale: 1;
      width: 0px;
      aspect-ratio: 1/1;
      background-color: var(--buttonHover);
      border-radius: 50%;
      animation-fill-mode: forwards;
    }
  }

  &:hover {
    .hoverEffect {
      &::before {
        animation: expandEffect 0.5s linear forwards;
      }
    }
  }

  @keyframes expandEffect {
    0% {
      width: 0px;
    }
    100% {
      width: 200%;
    }
  }
}
